import './App.css';



function App() {

    return (
        <div className="App">
            <div className="block shadow">
                Fanta AI
            </div>
            <p className="subtitle">
                Scatena il potere dell'<span className={"highlight"}>intelligenza artificiale</span> <br/> per vincere
                all'asta e dominare nella tua lega 😎
            </p>

            <a className="link" href="https://docs.google.com/forms/d/e/1FAIpQLSc5yMe7sT-4xJCCBNMaFqBiHtdFCZT0FeRJqgXsl368JJR58w/viewform" target="_blank">
                👉  Registrati alla closed beta  👈
            </a>
        </div>
    );
}

export default App;
